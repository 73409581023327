import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreConfig} from './core.config';
import {AuthConfig} from './models/auth-config.model';
import {
  ServerErrorListComponent
} from './utilities/form-utilities/components/server-error-list/server-error-list.component';
import {LoadingSpinnerComponent} from './utilities/loading-spinner/loading-spinner.component';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {FeatherIconModule} from './feather-icon/feather-icon.module';
import {VarDirective} from "./utilities/ng-var.directive";
import {AppDatePipe} from "./pipes/app-date.pipe";
import {BUILD_NUMBER, COMMIT_HASH} from "../../environments/version";
import {AutofocusDirective} from "./directives/autofocus.directive";

const PROVIDERS: any[] = [

  // Services

  // Resources

  // States

  // Guards
  ServerErrorListComponent,
  LoadingSpinnerComponent,

  // Interceptors
  SafeHtmlPipe,
  AppDatePipe,

  // Directives
  VarDirective,
  AutofocusDirective,
];

// Injection token for config overrides to use in factory
export const CoreConfigOverrides = new InjectionToken('CoreConfigOverrides');

// Factory to make config with overrides
export function coreConfigFactory(coreConfigOverrides: CoreConfig): CoreConfig {
  const config = CoreConfig.fromConfig(coreConfigOverrides);
  config.setGitHash(COMMIT_HASH);
  config.setBuildNumber(BUILD_NUMBER);
  return config;
}

@NgModule({
  imports: [
    CommonModule,
    FeatherIconModule,
  ],
  providers: PROVIDERS,
  declarations: PROVIDERS,
  exports: [
    ServerErrorListComponent,
    LoadingSpinnerComponent,
    SafeHtmlPipe,
    VarDirective,
    AppDatePipe,
    AutofocusDirective,
  ]
})
export class CoreModule {
  public static withConfig(coreConfig: {
    auth: Partial<AuthConfig>;
    appId: string;
    apiHost: string;
    api: { baseUrl: string; appConfigUrl: string }
    webBaseUrl: string;
    featureFlags?: {[key: string]: string};
  }): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: CoreConfigOverrides,
          useValue: coreConfig,
        },
        {
          provide: CoreConfig,
          useFactory: coreConfigFactory,
          deps: [CoreConfigOverrides],
        },
        ...PROVIDERS,
      ],
    };
  }
}

