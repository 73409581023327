import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {map} from "rxjs/operators";
import {CoreConfig} from "../core.config";
import {DominoConfig} from "../models/domino-config.model";

@Injectable({
  providedIn: 'root'
})
export class DominoConfigService {
  public dominoConfig: DominoConfig;

  constructor(
    private http: HttpClient,
    public coreConfig: CoreConfig) {
  }

  getAppConfig(): Observable<DominoConfig> {
    return this.http.get<DominoConfig>(`${this.coreConfig.api.baseUrl}/${this.coreConfig.api.appConfigUrl}`).pipe(
      tap((result) => this.dominoConfig = result),
    );
  }

  getFakeAppConfig(): DominoConfig {
    return {"success":true,
      "company":"VIP",
      "nrOfOrgLevels":3,
      "categorizeCollsByPublisher":false,
      "useemergency":false,
      "usePrivateNotes":true,
      "useemail":false,
      "usepincode":false,
      "useautonumber":true,
      "usemedialibrary":true,
      "collectiondefaultanonymous":"1",
      "collectiondefaultlogon":"4",
      "printfootercontent":"<br><table width=100% style=\"font-size: 12px; border-width: 0\"><tr><td colspan=\"2\" style=\"border-width: 0; padding: 0px\">%title%, %labelversion% %version%<\/td><\/tr><tr><td style=\"border-width: 0; padding: 0px\">%labelprinted%: %today% %labelby% %username%<\/td><td style=\"border-width: 0; padding: 0px\" align=right>%page% %labelof% %pages%<\/td><\/tr><\/table>",
      "printhmargin":"",
      "printvmargin":"",
      "hostname":"symdev.improvento.com",
      "hostnamepdf":"188.244.78.168",
      "ipAddress":"172.20.157.16:63148",
      "systemtitle":"",
      "userlanguage":"DA",
      "webhelpurl":"/WebHelp/$First?OpenDocument",
      "whitelabel":{
        "publisherlevel0":"092327",
        "publisherlevel1":"255C99",
        "publisherlevel2":"E2A601",
        "publishertextlevel0": "Region",
        "publishertextlevel1": "Enhed",
        "publishertextlevel2": "Afdeling",
        "topbarannouncement":"Forsmag på kommende e-Dok. Fejl og mangler kan forekomme og rettes løbende – tak fordi du er med til at afprøve. Forsmag på kommende e-Dok. Fejl og mangler kan forekomme og rettes løbende – tak fordi du er med til at afprøve. Forsmag på kommende e-Dok. Fejl og mangler kan forekomme og rettes løbende – tak fordi du er med til at afprøve. Forsmag på kommende e-Dok. Fejl og mangler kan forekomme og rettes løbende – tak fordi du er med til at afprøve.",
        "topbarannouncementcolor":"E80808",
        "colorlocalunitsbtn":"fdff3d",
        "brandcolor":"F05027",
        "favicon":"/favicon.ico",
        "logotop":"/images/DMSlogo.png/$FILE/DMSlogo.png",
        "logovenstre":"/images/DMSlogo.png/$FILE/venstrelogony.png"
      },
      "containerDatabase":"CUST/RegionHovedstaden/Slutbruger/Container.nsf",
      "collectionDatabase":"CUST/RegionHovedstaden/Dokumentsamlinger/collection.nsf",
      "favoriteDatabase":"CUST/RegionHovedstaden/Favorite/favorites.nsf",
      "subscriptionDatabase":"CUST/RegionHovedstaden/Subscription/subscriptions.nsf",
      "mypageDatabase":"CUST/RegionHovedstaden/minside/MinSide.nsf",
      "organisationDatabase":"CUST/RegionHovedstaden/Admin/OrgbaseDMS.nsf",
      "graphicsDatabase":"CUST/RegionHovedstaden/Admin/Upload.nsf",
      "portaldatabase":"CUST/RegionHovedstaden/Slutbruger/portal.nsf",
      "uploaddatabase":"CUST/RegionHovedstaden/Admin/Upload.nsf",
      "guidatabase":"CUST/RegionHovedstaden/Admin/GUI.nsf",
      "mediadatabase":"CUST/RegionHovedstaden/Media/media.nsf",
      "pdfDatabase":"CUST/RegionHovedstaden/Redaktoer/PDFDMS30.nsf",
      "adminDatabase":"cust/regionhovedstaden/Admin/Admin.nsf"
    } as Partial<DominoConfig> as DominoConfig;

  }
}
