import {inject, Injectable} from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {ProfileStateService} from "../states/profile-state.service";
import {CoreConfig} from "../core.config";
import {first, Observable, of, switchMap, tap, zip} from "rxjs";
import {Profile} from "../models/profile.model";
import {map} from "rxjs/operators";
import {ProfileResource} from "../resources/profile.resource";
import {DominoUserProfile, DominoUserRolesResponse} from "../models/domino-response.model";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  profileResource = inject(ProfileResource);
  profileStateService = inject(ProfileStateService);
  coreConfig = inject(CoreConfig);

  getProfileAndUpdateState(): Observable<Profile | null> {
    return zip(
      this.profileResource.getUserProfile(),
      this.profileResource.getUserRoles(),
    ).pipe(
      map(([dominoUserProfile, dominoRoles]) => {
        if (!dominoUserProfile) {
          return null;
        }
        return this.mapToProfile(dominoUserProfile, dominoRoles);
      }),
      tap(userProfile => this.profileStateService.updateProfile(userProfile)),
    );
  }


  updateProfile(postData: Partial<{
    email: string,
    pincode: string,
    searchLevel: string,
    startOverview: string,
    startCollectionLevel: string,
    startCollection: string,
    startCollectionDisplay: string,
  }>) {
    return this.profileResource.updateUserProfile(postData).pipe(
      tap(userProfile => this.getProfileAndUpdateState().subscribe())
    );
  }

  getStartPageRedirectUrl() {
    return this.profileStateService.profile$.pipe(
      first(),
      map(profile => {
        if (!profile) {
          return this.getStartpageSystemDefault(profile);
        }
        return this.getRedirectUrlForAuthenticatedUsers(profile);
      })
    );
  }

  private getRedirectUrlForAuthenticatedUsers(profile: Profile): string {
    if (profile.startOverview === 'search') {
      return '/documents';
    }
    if (profile.startOverview === 'favorites') {
      return '/favorites';
    }
    if (profile.startOverview === 'subscription') {
      return '/subscriptions';
    }
    if (profile.startOverview === 'collection') {
      if (profile.startCollectionId) {
        return '/collections/' + profile.startCollectionId;
      }

      if (profile.startCollectionLevel === 'collectionall') {
        return '/collections?publisher=ALL';
      }
      if (profile.startCollectionLevel === 'collectionniv1') {
        return '/collections?publisher=MAIN';
      }
      if (profile.startCollectionLevel === 'collectionniv2') {
        return '/collections?publisher=' + profile.levelDefault1;
      }
      if (profile.startCollectionLevel === 'collectionniv3') {
        return '/collections?publisher=' + profile.levelDefault;
      }
      return '/collections';
    }

    return this.getStartpageSystemDefault(profile);
  };

  private getStartpageSystemDefault(profile: Profile | null) {
    return '/documents';
  }

  private mapToProfile(dominoUserProfile: DominoUserProfile, dominoRoles: string[]): Profile {
    const profile: Profile = {
      firstname: dominoUserProfile.firstname,
      lastname: dominoUserProfile.lastname,
      mail: dominoUserProfile.mailaddress,
      username: dominoUserProfile.username,
      shortname: dominoUserProfile.shortname,
      level: dominoUserProfile.level1 + dominoUserProfile.level2,
      level1: dominoUserProfile.level1,
      level2: dominoUserProfile.level2,
      levelSearch: dominoUserProfile.level1search + dominoUserProfile.level2search,
      levelSearch1: dominoUserProfile.level1search,
      levelSearch2: dominoUserProfile.level2search,
      levelDefault: "",
      levelDefault1: "",
      levelDefault2: "",
      startOverview: dominoUserProfile.activetabenduser,
      startCollectionLevel: dominoUserProfile.activetabcollection,
      startCollectionId: dominoUserProfile.defaultcollection,
      startCollectionTitle: dominoUserProfile.defaultcollectiondisplay,
      roles: dominoRoles,
    }

    profile.levelDefault1 = profile.levelSearch ? profile.levelSearch1 : profile.level1;
    profile.levelDefault2 = profile.levelSearch ? profile.levelSearch2 : profile.level2;
    profile.levelDefault = profile.levelDefault1 + profile.levelDefault2;
    return profile;
  }
}
